import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import React, { ReactNode } from "react"
import { twMerge } from "tailwind-merge"
import { Icon } from "~/src/components/Icon"
import { StockIndicator } from "~/src/components/StockIndicator"
import { SelectableType, Variant } from "~/src/types/productSelection"

export type VariantDropdownProps = {
  variants: Variant[]
  selectedVariant: Variant
  inventoryRequired: boolean
  label: string | ReactNode
  onChange: (variant: Variant) => void
}

export function VariantDropdown(props: VariantDropdownProps) {
  const { variants, selectedVariant, inventoryRequired, onChange, label } = props
  return (
    <div className="flex flex-col gap-1">
      {label ? <p className="font-medium">{label}</p> : <></>}
      <Listbox value={selectedVariant} onChange={onChange}>
        <div className="flex flex-col gap-1">
          <ListboxButton
            className={({ open }) =>
              twMerge(
                "relative md:w-80 w-full cursor-default overflow-hidden rounded-lg border border-grey-500 bg-white text-left sm:text-sm py-2 px-4",
                open && "ring-1 ring-gray-700"
              )
            }
          >
            {selectedVariant.id ? (
              <VariantDisplay variant={selectedVariant} />
            ) : (
              <p className="text-gray-500 text-sm">Select one</p>
            )}
            <div className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </ListboxButton>
          <ListboxOptions
            modal={false}
            className="z-50 md:absolute flex flex-col md:mt-10 max-h-60 md:w-80 overflow-auto rounded-md bg-white drop-shadow-sm ring-1 ring-black divide-y ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {variants.map((variant, i) => {
              const { inStock, inventoryCount } = variant
              return (
                <ListboxOption
                  key={i}
                  value={variant}
                  disabled={(!inStock || inventoryCount <= 0) && inventoryRequired}
                  className={({ focus, selected, disabled }) =>
                    twMerge(
                      "relative cursor-default select-none px-4 py-3 text-gray-900",
                      focus && "bg-gray-700 text-white cursor-pointer",
                      selected && !focus && "bg-navy-100 font-medium",
                      disabled && "bg-gray-100 text-gray-400"
                    )
                  }
                >
                  <VariantOption inventoryRequired={inventoryRequired} variant={variant} />
                </ListboxOption>
              )
            })}
          </ListboxOptions>
        </div>
      </Listbox>
    </div>
  )
}

function variantName({ colorName, size, flavor }: Variant) {
  return [colorName, size, flavor].filter((x) => x).join(" / ")
}

function VariantDisplay({ variant }: { variant: Variant }) {
  const { colorCode, colorId } = variant
  return (
    <div className="flex gap-2 items-center">
      {colorCode && colorId ? <Icon.Dot className="h-4 w-4" style={{ color: colorCode }} /> : <></>}
      <p className="text-sm">{variantName(variant)}</p>
    </div>
  )
}

function VariantOption({ variant, inventoryRequired }: { inventoryRequired: boolean; variant: Variant }) {
  const { inventoryCount } = variant
  return (
    <div className="flex justify-between">
      <VariantDisplay variant={variant} />
      <StockIndicator type={SelectableType.StoreProduct} quantity={inventoryCount} required={inventoryRequired} />
    </div>
  )
}
