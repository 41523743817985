import React, { useState } from "react"
import { dataFlag } from "~/src/lib/jsx"

export type Color = {
  colorCode?: string
  colorName: string
  fallbackImageUrl: string
}

export type ColorSwatchProps = {
  colors: Color[]
  onClick?: (color: Color, index?: number) => void
  defaultColor?: Color
}

export const ColorSwatch: React.FC<ColorSwatchProps> = ({ colors, onClick, defaultColor }) => {
  if (colors.length === 0) {
    return null
  }

  const initialColor =
    defaultColor && colors.some((color) => color.colorCode === defaultColor.colorCode) ? defaultColor : colors[0]

  const [selectedColor, setSelectedColor] = useState<Color>(initialColor)

  const handleColorClick = (color: Color, index: number) => {
    setSelectedColor(color)
    onClick?.(color, index)
  }

  return (
    <section className="flex flex-col gap-2 mt-3 mb-3">
      <h1 className="text-left text-base font-medium leading-6 text-gray-900">
        Color: <span className="font-normal text-gray-600">{selectedColor?.colorName || ""}</span>
      </h1>
      <section className="flex items-center gap-2 flex-wrap">
        {colors.map((color, index) => {
          const isSelected =
            color.colorCode === selectedColor?.colorCode && color.colorName === selectedColor?.colorName
          return (
            <div
              key={index}
              className={
                "flex items-center justify-center w-8 h-8 rounded-full cursor-pointer border-2 border-transparent data-[selected]:border-gray-900"
              }
              {...dataFlag(isSelected, "selected")}
              onClick={() => handleColorClick(color, index)}
              title={color.colorName}
              style={{ boxSizing: "border-box" }}
            >
              {color.colorCode ? (
                <div
                  className="w-6 h-6 rounded-full border-gray-300 border"
                  style={{ backgroundColor: color.colorCode }}
                />
              ) : (
                <div className="flex w-6 h-6 rounded-full border-gray-300 border overflow-hidden justify-center items-center">
                  <img
                    className="blur-sm"
                    src={color.fallbackImageUrl}
                    style={{
                      width: "400%",
                      height: "400%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </div>
              )}
            </div>
          )
        })}
      </section>
    </section>
  )
}
