import { CheckCircleIcon } from "@heroicons/react/20/solid"
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline"
import React, { useMemo, useRef, useState } from "react"
import { WorkflowContainer } from "~/src/components/WorkflowContainer"
import { summarizeSelections } from "~/src/lib/quickSend"
import { BudgetAmount } from "~/src/types/budget"
import { Orientation } from "~/src/types/orientation"
import { QuickSend, QuickSendKind, QuickSendSelectionKind } from "~/src/types/preferredGift"
import {
  SelectableType,
  type ProductSelectionItem,
  type QuickSendSelectionItem,
  type Selectable,
  type StoreProduct,
  type StoreProductGroup,
  type Variant,
} from "~/src/types/productSelection"
import { Bee } from "./BeeKit"
import { BudgetDisplayPill } from "./BudgetDisplayPill"
import { ProductSelection } from "./ProductSelection"
import { VariantDetailModal } from "./ProductSelection/VariantDetailModal"
import { QuickSendEditBaseErrorsFlash } from "./QuickSendEditBaseErrorsFlash"
import { QuickSendEditSelectionInputs } from "./QuickSendEditSelectionInputs"
import { QuickSendProductSelectionSummary } from "./QuickSendProductSelectionSummary"
import { WorkflowHeading } from "./WorkflowHeading"

export type QuickSendEditSelectionsContentProps = {
  quickSend: QuickSend
  formUrl: string
  backUrl: string
  selections: ProductSelectionItem[]
  availableStoreBudget: BudgetAmount
  customBudgetUnit?: string
  storeProductGroups: StoreProductGroup[]
}

export function QuickSendEditSelectionsContent(props: QuickSendEditSelectionsContentProps) {
  const { quickSend, formUrl, backUrl, availableStoreBudget, customBudgetUnit } = props

  const quickSendKind = quickSend.kind
  const selections = quickSend.selections

  const storeProductGroups = useMemo(
    () => props.storeProductGroups.filter((productGroup) => productGroup.selectables.length > 0),
    [props.storeProductGroups]
  )

  const scrollRoot = useRef<HTMLDivElement>(null)
  const storeProductGroupTabs = storeProductGroups.map((productGroup) => {
    return { label: productGroup.name, ref: useRef<HTMLDivElement>(null) }
  })

  return (
    <ProductSelection.FormPage
      formUrl={formUrl}
      selections={selections}
      storeProductGroups={storeProductGroups}
      hiddenInputs={<QuickSendEditSelectionInputs />}
    >
      <div className="h-[calc(100vh-3.5rem)] flex flex-col gap-8 md:pb-6 pb-32">
        <WorkflowContainer className="grow flex flex-col gap-8 overflow-y-scroll" ref={scrollRoot}>
          <Heading budget={availableStoreBudget} currencyUnit={customBudgetUnit} quickSendKind={quickSendKind} />
          <div className="md:hidden flex">
            <ProductSelection.Description />
          </div>

          <QuickSendEditBaseErrorsFlash quickSend={quickSend} className="m-0" />

          <OverBudgetFlash availableStoreBudget={availableStoreBudget} />

          <div className="flex md:flex-row flex-col mt-4">
            <div className="flex flex-col gap-8 w-full md:pr-6">
              <ProductSelection.SearchInput liveSearch />
              {/* We set negative top offset on the container to snap it to the top of the screen instead of inplace below the livesearch*/}
              <ProductSelection.CategoryTabBar
                tabs={storeProductGroupTabs}
                scrollRoot={scrollRoot}
                scrollOffset={72}
                containerClassName="-top-14 -mb-4 pb-4 -mt-7"
                className="bg-white"
              />
              {storeProductGroups.map((spg: StoreProductGroup, index: number) => (
                <section key={spg.id} ref={storeProductGroupTabs[index].ref}>
                  <ProductSelection.StoreProductGroupTileList
                    itemSelected={(item: QuickSendSelectionItem) =>
                      !!item?.kind && item.kind != QuickSendSelectionKind.None
                    }
                    storeProductGroup={spg}
                    orientation={Orientation.Portrait}
                    renderSelectionControls={(item: Selectable) => (
                      <QuickSendProductSelectionControls item={item} quickSendKind={quickSendKind} />
                    )}
                  />
                </section>
              ))}
            </div>
            <div className="hidden md:block">
              <QuickSendProductSelectionSummary
                quickSendKind={quickSendKind}
                productFilter={(item: QuickSendSelectionItem) =>
                  !!item?.kind && item.kind != QuickSendSelectionKind.None
                }
              />
            </div>
          </div>
        </WorkflowContainer>

        <div className="w-full md:relative fixed bottom-0 bg-gray-50">
          <div className="flex flex-col w-full">
            <div className="bg-white w-full md:hidden">
              <QuickSendProductSelectionSummary
                quickSendKind={quickSendKind}
                productFilter={(item: QuickSendSelectionItem) =>
                  !!item?.kind && item.kind != QuickSendSelectionKind.None
                }
              />
            </div>
            <QuickSendSelectionSubmissionControls backUrl={backUrl} availableStoreBudget={availableStoreBudget} />
          </div>
        </div>
      </div>
    </ProductSelection.FormPage>
  )
}

function Heading({
  budget,
  currencyUnit,
  quickSendKind,
}: {
  budget: BudgetAmount
  currencyUnit?: string
  quickSendKind: QuickSendKind
}) {
  return (
    <WorkflowHeading title="Select products" subtitle={subheadingCopy(quickSendKind)}>
      <BudgetDisplayPill preamble="Available store budget: " budget={budget} currencyUnit={currencyUnit} />
    </WorkflowHeading>
  )
}

function subheadingCopy(quickSendKind: QuickSendKind) {
  if (quickSendKind === QuickSendKind.Surprise) {
    return "Select one or more items to send directly to a recipient"
  } else {
    return "Select one or more items to send directly to a recipient, or create a selection of items for them to choose from."
  }
}

function useIsEstimatedTotalOverStoreBudget(availableStoreBudget: BudgetAmount) {
  const selectedItems = Object.values(ProductSelection.useSelectedItems<QuickSendSelectionItem>())

  if (availableStoreBudget === null) {
    return false
  } else {
    const { estimatedTotal } = summarizeSelections(selectedItems)
    return estimatedTotal > availableStoreBudget
  }
}

function OverBudgetFlash(props: { availableStoreBudget: BudgetAmount }) {
  const { availableStoreBudget } = props

  return useIsEstimatedTotalOverStoreBudget(availableStoreBudget) ? (
    <Bee.Flash kind="error" className="mt-4">
      <p>
        You don&apos;t have enough budget available for this send. Please remove items or reach out to your Brilliant
        administrator about your budget.
      </p>
    </Bee.Flash>
  ) : null
}

function IncludedGiftButtonContent({ isGifted, quickSendKind }: { isGifted: boolean; quickSendKind: QuickSendKind }) {
  const unselectedText = quickSendKind === QuickSendKind.Standard ? "Add as included gift" : "Select gift"
  const selectedText = quickSendKind === QuickSendKind.Standard ? "Included gift" : "Selected"

  return buttonText(isGifted, selectedText, unselectedText)
}

function QuickSendSelectionSubmissionControls({
  backUrl,
  availableStoreBudget,
}: {
  backUrl: string
  availableStoreBudget: BudgetAmount
}) {
  const selections = ProductSelection.useSelectedItems<QuickSendSelectionItem>()
  const noSelections =
    Object.values(selections).filter((item: QuickSendSelectionItem) => item.kind != QuickSendSelectionKind.None)
      .length == 0

  return (
    <div className="px-6 flex justify-center gap-4 bg-gray-50 w-full md:py-0 py-4">
      <Bee.ButtonLink className="flex items-center gap-1" kind="secondary" href={backUrl}>
        <ArrowLeftIcon className="h-4 w-4" />
        <span>Back</span>
      </Bee.ButtonLink>

      <Bee.Button
        type="submit"
        className="flex items-center gap-1"
        disabled={noSelections || useIsEstimatedTotalOverStoreBudget(availableStoreBudget)}
      >
        <span>Continue</span>
        <ArrowRightIcon className="h-4 w-4" />
      </Bee.Button>
    </div>
  )
}

const buttonText = (selected: boolean, selectedText: string, unSelectedText: string) => {
  if (selected) {
    return (
      <div className="flex flex-row items-center justify-center gap-1">
        <CheckCircleIcon className="w-4 h-4" />
        {selectedText}
      </div>
    )
  } else {
    return unSelectedText
  }
}

type SelectionControlProps = {
  item: Selectable
  quickSendKind: QuickSendKind
}

function QuickSendProductSelectionControls(props: SelectionControlProps) {
  const { item, quickSendKind } = props
  const selectedItems = ProductSelection.useSelectedItems<QuickSendSelectionItem>()
  const setSelected = ProductSelection.useSetSelectedItem()
  const selectedItem = selectedItems[item.id]

  const selectionType = selectedItem?.kind || QuickSendSelectionKind.None
  const chosen = selectionType == QuickSendSelectionKind.Chosen
  const gifted = selectionType == QuickSendSelectionKind.Gifted

  const isDisabled = (selectable: Selectable): boolean => {
    if (selectable.type == SelectableType.StoreProduct) {
      const { inventoryCount, variantCount, inventoryRequired } = selectable as StoreProduct

      if ((inventoryCount <= 0 || variantCount == 0) && inventoryRequired) {
        return true
      }
    } // else if (selectable.type == SelectableType.StoreProduct) required for StoreKit support

    return false
  }

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  const onChoice = (kind: QuickSendSelectionKind, selectedVariant?: Variant) => {
    const selection: ProductSelectionItem = {
      id: selectedItem?.id,
      type: "QuickSend",
      selectableId: item.id,
      selectableType: item.type,
      selectable: item,
      kind,
      selectedVariant,
    }

    setSelected(selection)
  }

  const onGiftClick = (kind: QuickSendSelectionKind, selectedVariant?: Variant) => {
    if (!gifted && item.type == SelectableType.StoreProduct) {
      const { variantCount } = item as StoreProduct

      if (variantCount > 1) {
        setModalIsOpen(true)
        return
      }
    }

    onChoice(kind, selectedVariant)
  }

  const StandardQuickSendButtons = () => (
    <Bee.ButtonGroup>
      <Bee.Button
        onClick={() => onChoice(chosen ? QuickSendSelectionKind.None : QuickSendSelectionKind.Chosen)}
        type="button"
        kind="secondary"
        className={chosen ? "text-white bg-navy-800" : "border-gray-300"}
        disabled={isDisabled(item)}
      >
        {buttonText(chosen, "Added as option", "Add as option")}
      </Bee.Button>
      <Bee.Button
        onClick={() => onGiftClick(gifted ? QuickSendSelectionKind.None : QuickSendSelectionKind.Gifted)}
        type="button"
        kind="secondary"
        className={gifted ? "text-white bg-navy-800" : "border-gray-300"}
        disabled={isDisabled(item)}
      >
        <IncludedGiftButtonContent isGifted={gifted} quickSendKind={quickSendKind} />
      </Bee.Button>
    </Bee.ButtonGroup>
  )

  const SurpriseSendButtons = () => (
    <Bee.Button
      onClick={() => onGiftClick(gifted ? QuickSendSelectionKind.None : QuickSendSelectionKind.Gifted)}
      type="button"
      kind="secondary"
      className={gifted ? "text-white bg-navy-800" : "border-gray-300"}
      disabled={isDisabled(item)}
    >
      <IncludedGiftButtonContent isGifted={gifted} quickSendKind={quickSendKind} />
    </Bee.Button>
  )

  return (
    <>
      {quickSendKind === QuickSendKind.Standard ? <StandardQuickSendButtons /> : <SurpriseSendButtons />}
      <VariantDetailModal
        selectable={item}
        selectedVariant={selectedItem?.selectedVariant}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        onChoice={onChoice}
      />
    </>
  )
}
