import React from "react"
import { twMerge } from "tailwind-merge"
import { Orientation } from "~/src/types/orientation"

export type PriceQuantity = {
  price: number
  quantity: number
}

export type ProductPricingTableProps = {
  priceQuantities: PriceQuantity[]
  orientation?: Orientation
  className?: string
  responsive?: boolean
}

function ProductPricingTableLandscape(props: ProductPricingTableProps) {
  const { priceQuantities, className, responsive = true } = props

  const responsiveClassNames = responsive ? "hidden sm:flex" : "flex"

  return (
    <>
      <div
        className={twMerge(
          `${responsiveClassNames} flex-col flex-1 items-start border border-gray-300 bg-white overflow-auto`,
          className
        )}
      >
        <table className="min-w-full divide-y divide-gray-200 table-auto">
          <thead>
            <tr className="divide-x">
              <th className="px-[16px] py-[10px] min-w-[72px] text-left text-xs font-bold text-gray-600 border-gray-300 bg-gray-50">
                Quantity
              </th>
              {priceQuantities.map((pq, index) => (
                <th
                  key={index}
                  className="px-[16px] py-[10px] min-w-[72px] text-left text-xs font-normal text-gray-900 border-gray-300"
                >
                  {pq.quantity}+
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="divide-x">
              <td className="px-[16px] py-[10px] min-w-[72px] text-left text-xs font-bold text-gray-600 border-gray-300 bg-gray-50">
                Price
              </td>
              {priceQuantities.map((pq, index) => (
                <td
                  key={index}
                  className="px-[16px] py-[10px] min-w-[72px] text-left text-xs font-normal text-gray-900 border-gray-300"
                >
                  ${pq.price.toFixed(2)}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>

      {responsive && (
        <ProductPricingTablePortrait
          className={twMerge("flex sm:hidden", className)}
          priceQuantities={priceQuantities}
        />
      )}
    </>
  )
}

function ProductPricingTablePortrait(props: ProductPricingTableProps) {
  const { priceQuantities, className } = props

  return (
    <div
      className={twMerge("flex flex-col flex-1 items-start border border-gray-300 bg-white overflow-auto", className)}
    >
      <table className="w-full divide-y divide-gray-200 table-auto">
        <thead>
          <tr className="divide-x">
            <th className="px-[16px] py-[10px] text-left text-xs font-bold text-gray-600 border-gray-300 bg-gray-50">
              Quantity
            </th>
            <th className="px-[16px] py-[10px] text-left text-xs font-bold text-gray-600 border-gray-300 bg-gray-50">
              Price
            </th>
          </tr>
        </thead>
        <tbody>
          {priceQuantities.map((pq, index) => (
            <tr key={index} className="divide-x border-t border-gray-300">
              <td className="px-[16px] py-[10px] text-left text-xs text-gray-900" style={{ width: "105px" }}>
                {pq.quantity}+
              </td>
              <td className="px-[16px] py-[10px] text-left text-xs text-gray-900" style={{ width: "105px" }}>
                ${pq.price.toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export function ProductPricingTable(props: ProductPricingTableProps) {
  const { orientation = Orientation.Landscape, ...restProps } = props

  if (orientation === Orientation.Landscape) {
    return <ProductPricingTableLandscape {...restProps} />
  } else {
    return <ProductPricingTablePortrait {...restProps} />
  }
}
