// This file includes components used by our client facing UI.
// Please lazily load large components.
import React from "react"
import { registerComponents } from "~/src/lib/reactComponentRegistry"

import {
  BudgetDisplayPill,
  ContactUs,
  DashboardDebugBox,
  DisplayTagList,
  DropshipEditor,
  LegacyCatalog,
  LookbookProductFilter,
  OrderRecipientDelivery,
  ProductDetail,
  QuickSendConfirmationContent,
  QuickSendEditDetailsContent,
  QuickSendEditDetailsContentLegacy,
  QuickSendEditSelectionsContent,
  QuickSendEditStoreContent,
  QuickSendReviewContent,
  ReactionStatistics,
  SignIn,
  Spreadsheet,
  StoreKitInstanceForm,
  StoreProductWarnings,
  StorefrontOrderHistoryControls,
  TeamForm,
  TeamsTable,
  ThankYouNoteConfirmation,
  ThankYouNoteLanding,
  ThankYouNoteMessageCarousel,
  ThankYouNotesIndex,
} from "~/src/components"
import { DashboardCatalogRoutes } from "~/src/components/Catalog/DashboardCatalogRoutes"
import { SolutionInstanceToggle } from "~/src/components/Solutions/SolutionInstanceToggle"

registerComponents({
  BudgetDisplayPill,
  LegacyCatalog,
  CustomTraySolutionForm: React.lazy(() =>
    import("~/src/components/Solutions/CustomTraySolutionForm").then(({ CustomTraySolutionForm }) => ({
      default: CustomTraySolutionForm,
    }))
  ),
  CustomTraySolutionSelectorForm: React.lazy(() =>
    import("~/src/components/Solutions/CustomTraySolutionSelectorForm").then(({ CustomTraySolutionSelectorForm }) => ({
      default: CustomTraySolutionSelectorForm,
    }))
  ),
  DashboardDebugBox,
  DisplayTagList,
  DropshipEditor,
  LookbookProductFilter,
  OrderRecipientDelivery,
  DashboardCatalogRoutes,
  ProductDetail,
  Spreadsheet,
  StorefrontOrderHistoryControls,
  StoreKitInstanceForm,
  TeamsTable,
  TeamForm,
  ThankYouNoteConfirmation,
  ThankYouNoteLanding,
  ThankYouNoteMessageCarousel,
  QuickSendEditDetailsContent,
  QuickSendEditDetailsContentLegacy,
  QuickSendEditStoreContent,
  QuickSendEditSelectionsContent,
  QuickSendConfirmationContent,
  StoreProductWarnings,
  SolutionInstanceToggle,
  ThankYouNotesIndex,
  ContactUs,
  ReactionStatistics,
  QuickSendReviewContent,
  SignIn,
})
