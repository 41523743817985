import React from "react"

import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { ProductCatalogContent, ProductCatalogContentProps } from "~/src/components/Catalog/ProductCatalogContent"
import { appClient } from "~/src/lib/appClients"
import { ProductDetailModal } from "~/src/components/ProductDetailModal"

export const routes = {
  productCatalogDashboard: "/",
}

const router = createBrowserRouter(
  [
    {
      path: routes.productCatalogDashboard,
      element: <ProductCatalogContent />,
      async loader({ request }): Promise<ProductCatalogContentProps> {
        const url = new URL(request.url)

        const response = await appClient.get(url.pathname + ".json", {
          headers: { Accept: "application/json" },
          params: url.searchParams,
        })
        return response.data
      },
      children: [
        {
          path: "/products/:productId/:ideabookProductId",
          element: <ProductDetailModal />,
          async loader(args) {
            const { productId, ideabookProductId } = args.params
            const response = await appClient.get(`/products/${productId}/${ideabookProductId}`, {
              headers: { Accept: "application/json" },
            })
            return response.data
          },
        },
      ],
    },
  ],
  { basename: "/dashboard/catalog" }
)

/**
 * Renders the dashboard catalog routes at `/dashboard/catalog`.
 * @returns The rendered component.
 */
export function DashboardCatalogRoutes() {
  return <RouterProvider router={router} />
}
