import * as React from "react"
import { Bee } from "~/src/components/BeeKit"
import { useEffect, useState, useRef } from "react"
import { twMerge } from "tailwind-merge"
import { STORE_PRODUCT_GROUP_DEFAULT_LABEL } from "~/constants"

export type Tab = {
  label: string
  ref: React.RefObject<HTMLDivElement>
}

export type CategoryTabBarProps = {
  tabs: Tab[]
  scrollRoot: React.RefObject<HTMLDivElement>
  scrollOffset?: number
  className?: string
  containerClassName?: string
}

export function CategoryTabBar({
  tabs,
  scrollRoot,
  className,
  containerClassName,
  scrollOffset = 0,
}: CategoryTabBarProps) {
  const filteredTabs = tabs.filter((tab) => tab.label !== STORE_PRODUCT_GROUP_DEFAULT_LABEL)
  const tabHeaders = filteredTabs.map(() => useRef<HTMLButtonElement>(null))
  const [selectedIndex, setSelectedIndex] = useState(0)

  const calcSelectedIndex = () => {
    if (!scrollRoot.current) {
      return
    }

    if (filteredTabs.length < 1) {
      return
    }

    const scrollTop = scrollRoot.current.getBoundingClientRect().top + scrollOffset
    const firstVisibleIndex = filteredTabs.findIndex((tab) => {
      if (!tab.ref.current) {
        return false
      }

      const tabBottom = tab.ref.current.getBoundingClientRect().bottom
      return tabBottom >= scrollTop
    })

    if (firstVisibleIndex === -1) {
      setSelectedIndex(filteredTabs.length - 1)
    } else {
      setSelectedIndex(firstVisibleIndex)
    }
  }

  // Scroll header into view on index change
  useEffect(() => {
    const tabHeader = tabHeaders[selectedIndex]?.current
    if (tabHeader) {
      tabHeader.scrollIntoView({ block: "nearest" })
    }
  }, [selectedIndex])

  // Observe scrolling changes
  useEffect(() => {
    if (!scrollRoot.current) {
      return () => {}
    }

    const options: IntersectionObserverInit = {
      root: scrollRoot.current,
      rootMargin: `-${scrollOffset}px 0px 0px 0px`,
    }

    const observer = new IntersectionObserver(calcSelectedIndex, options)
    filteredTabs.forEach(({ ref }) => {
      if (ref.current) {
        observer.observe(ref.current)
      }
    })

    return () => {
      filteredTabs.forEach(({ ref }) => {
        if (ref.current) {
          observer.unobserve(ref.current)
        }
      })
    }
  }, [filteredTabs, scrollRoot, scrollOffset])

  return (
    <div className={twMerge("sticky", containerClassName)}>
      <Bee.TabBar className={className}>
        {filteredTabs.map((tab, index) => (
          <Bee.Tab
            ref={tabHeaders[index]}
            selected={index === selectedIndex}
            onClick={() => {
              if (scrollRoot.current && tab.ref.current) {
                const scrollOffsetTop = scrollRoot.current.offsetTop + scrollOffset
                const tabOffsetTop = tab.ref.current.offsetTop
                scrollRoot.current.scrollTo({
                  top: tabOffsetTop - scrollOffsetTop,
                })
                calcSelectedIndex()
              }
            }}
            key={index}
          >
            {tab.label}
          </Bee.Tab>
        ))}
      </Bee.TabBar>
    </div>
  )
}
