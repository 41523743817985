import React from "react"
import { twMerge } from "tailwind-merge"
import { type DisplayTag } from "~/src/types/displayTag"

import { Bee } from "~/src/components/BeeKit"

export type DisplayTagListProps = {
  displayTags: DisplayTag[]
  className?: string
  individualTagClassName?: string
  individualTagImgClassName?: string
}

export function DisplayTagList(props: DisplayTagListProps) {
  const { displayTags, className, individualTagClassName, individualTagImgClassName } = props

  return (
    <div className={twMerge("flex flex-col gap-3 pt-3", className)}>
      {displayTags.map(({ id, name, description, logo }) => {
        return (
          <Bee.DisplayTag
            key={id}
            name={name}
            description={description}
            logoUrl={logo ? logo.url : ""}
            className={individualTagClassName}
            imgClassName={individualTagImgClassName}
          />
        )
      })}
    </div>
  )
}
