import React from "react"

import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom"
import { Modal } from "~/src/components/Modal"
import { ProductDetail, ProductDetailProps } from "~/src/components/ProductDetail"

export type ProductDetailModalProps = Omit<ProductDetailProps, "onSubmit" | "backUrl">

export function ProductDetailModal() {
  const data = useLoaderData() as ProductDetailModalProps
  const { product, variantPhotos, priceGroup, displayTags, hidePrice, action, flavors, paramOpens } = data
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  let closeUrl = ".."
  if (searchParams) closeUrl += `?${searchParams}`

  return (
    <Modal
      open={true}
      onClose={() => navigate(closeUrl, { preventScrollReset: true })}
      backgroundClassName="bg-black bg-opacity-50 z-50"
      className="flex flex-col bg-white max-w-5xl max-h-[80vh]"
      usePortal={false}
    >
      <ProductDetail
        product={product}
        variantPhotos={variantPhotos}
        priceGroup={priceGroup}
        displayTags={displayTags}
        hidePrice={hidePrice}
        action={action}
        flavors={flavors}
        paramOpens={paramOpens}
      />
    </Modal>
  )
}
